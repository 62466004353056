import React from "react";
import { useTranslation } from "react-i18next";

function YearsSection() {
  const [t, i18n] = useTranslation("global");
  return (
    <section
      id="years"
      className="years"
      style={{ backgroundColor: "#FAC936" }}
    >
      <div className="container">
        <div className="row">
          <div className="years-inner">
            <div className="years-inner-div" data-aos="fade-up">
              <h3>2020</h3>
              <p>
                {t("yearsSection.yearLaunched.l1")}
                <br />
                {t("yearsSection.yearLaunched.l2")}
              </p>
            </div>
            <div className="years-inner-div" data-aos="fade-up">
              <h3>15K+</h3>
              <p>
                {t("yearsSection.activeUser.l1")}
                <br />
                {t("yearsSection.activeUser.l2")}
              </p>
            </div>
            <div className="years-inner-div" data-aos="fade-up">
              <h3>200+</h3>
              <p>
                {t("yearsSection.techniciansAppointed.l1")}
                <br />
                {t("yearsSection.techniciansAppointed.l2")}
              </p>
            </div>
            <div className="years-inner-div" data-aos="fade-up">
              <h3>600</h3>
              <p>
                {t("yearsSection.techniciansInTraining.l1")}
                <br />
                {t("yearsSection.techniciansInTraining.l2")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default YearsSection;
