import React from 'react'

function Hero() {
  return (
    <section id="home" className="hero">
      <div className="video-wrapper-banner">
        <video autoPlay muted loop playsInline preload="metadata">
          <source src="/assets/img/video.mp4" type="video/mp4" />
        </video>
      </div>
    </section>
  );
}

export default Hero