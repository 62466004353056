import { useEffect, useState } from "react";
import "./App.css";
import About from "./components/About";
import CopyRight from "./components/CopyRight";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";
import MyAppSection from "./components/MyAppSection";
import SectionEight from "./components/SectionEight";
import SectionFive from "./components/SectionFive";
import ServiceSection from "./components/ServiceSection";
import WelcomeSection from "./components/WelcomeSection";
import YearsSection from "./components/YearsSection";
import AOS from "aos";
import "aos/dist/aos.css";
import { BrowserRouter } from "react-router-dom";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 600,
      easing: 'ease-in-out',
      once: true,
      mirror: false
    });
  }, []);

  return (
    <BrowserRouter>
      <Header />
      <main id="main">
        <Hero />
        <WelcomeSection />
        <About />
        <YearsSection />
        <ServiceSection />
        <SectionFive />
        <MyAppSection />
        <SectionEight />
      </main>
      <Footer />
      <CopyRight />
    </BrowserRouter>
  );
}

export default App;
