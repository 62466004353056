import React from "react";
import { useTranslation } from "react-i18next";

function SectionFive() {
  const [t, i18n] = useTranslation("global");
  return (
    <>
      <section id="section-5" className="section-5">
        <div className="container">
          <div className="row">
            <div className="service-inner-title">
              <h2>{t("serviceSteps.header")}</h2>
              <p>
                {t("serviceSteps.body.t1")}{" "}
                <strong>{t("serviceSteps.body.t2")}</strong>{" "}
                {t("serviceSteps.body.t3")}{" "}
                <strong>{t("serviceSteps.body.t4")}</strong>
              </p>
            </div>
            <div className="section-5-process">
              <ul>
                <li data-aos="fade-left" data-aos-duration={5000}>
                  <div className="section-5-process-icon">
                    <svg
                      preserveAspectRatio="xMidYMid meet"
                      data-bbox="19.26 24.11 60.58 60.7"
                      viewBox="19.26 24.11 60.58 60.7"
                      xmlns="http://www.w3.org/2000/svg"
                      data-type="shape"
                      role="img"
                      aria-label="request"
                    >
                      <g>
                        <path d="M78.54 82.27h-2.52c-1.41 0-2.52-1.11-2.52-2.52v-5.04c0-2.82-2.22-5.04-5.04-5.04s-5.04 2.22-5.04 5.04v5.04c0 1.41-1.11 2.52-2.52 2.52s-2.52-1.11-2.52-2.52v-5.04c0-2.82-2.22-5.04-5.04-5.04s-5.04 2.22-5.04 5.04v5.04c0 1.41-1.11 2.52-2.52 2.52s-2.52-1.11-2.52-2.52v-5.04c0-2.82-2.22-5.04-5.04-5.04s-5.04 2.22-5.04 5.04v5.04c0 1.41-1.11 2.52-2.52 2.52s-2.52-1.11-2.52-2.52V64.63h5.04c.71 0 1.31-.6 1.31-1.31V53.24c0-.71-.6-1.31-1.31-1.31h-3.83V36.81h3.83c.71 0 1.31-.61 1.31-1.31V25.42c0-.71-.6-1.31-1.31-1.31h-5.65c-4.54 0-8.27 3.73-8.27 8.27v24.1c0 3.83 2.72 7.16 6.35 7.97v15.32c0 2.82 2.22 5.04 5.04 5.04s5.04-2.22 5.04-5.04v-5.04c0-1.41 1.11-2.52 2.52-2.52s2.52 1.11 2.52 2.52v5.04c0 2.82 2.22 5.04 5.04 5.04s5.04-2.22 5.04-5.04v-5.04c0-1.41 1.11-2.52 2.52-2.52s2.52 1.11 2.52 2.52v5.04c0 2.82 2.22 5.04 5.04 5.04s5.04-2.22 5.04-5.04v-5.04c0-1.41 1.11-2.52 2.52-2.52s2.52 1.11 2.52 2.52v5.04c0 2.82 2.22 5.04 5.04 5.04h2.52c.71 0 1.31-.6 1.31-1.31-.1-.61-.61-1.21-1.31-1.21ZM21.78 56.46V32.57c0-3.13 2.52-5.75 5.75-5.75h4.34v7.56h-2.52v-1.31c0-.71-.6-1.31-1.31-1.31s-1.31.6-1.31 1.31v22.69c0 .71.6 1.31 1.31 1.31s1.31-.6 1.31-1.31v-1.31h2.52v7.56h-4.34c-3.13.1-5.75-2.42-5.75-5.55Z"></path>
                        <path d="M44.56 63.02c-.2.81.4 1.61 1.21 1.61.2 0-.3.1 9.07-1.61 10.99 2.32 21.17-6.15 21.17-17.24 0-9.68-7.86-17.64-17.64-17.64S40.73 36.1 40.73 45.78c0 4.74 1.92 9.17 5.14 12.5l-1.31 4.74Zm-1.31-17.24c0-8.37 6.76-15.12 15.12-15.12s15.12 6.75 15.12 15.12c0 9.58-8.87 16.84-18.35 14.72-.4-.1 0-.1-7.56 1.31l1.01-3.53c.1-.5 0-1.01-.4-1.31-3.13-2.92-4.94-6.96-4.94-11.19Z"></path>
                        <path d="M49.5 53.34c19.16 0 17.74 0 18.15-.1.5-.2.81-.6.81-1.21 0-13.71 0-12.7-.1-13.01-.1-.4-.5-.81-1.01-.81H49.5c-.4 0-.71.2-1.01.4-.1.1-.2.3-.2.4-.1.3 0-.6-.1 13.01.1.71.6 1.31 1.31 1.31ZM65.93 49l-3.73-3.63 3.63-3.13V49h.1Zm-8.37-1.01c.5.4 1.21.4 1.61 0l1.11-1.01 3.83 3.83H52.52l3.83-3.83 1.21 1.01Zm.81-2.62-5.44-4.64h10.79l-5.34 4.64Zm-3.93 0L50.81 49v-6.86l3.63 3.23Z"></path>
                      </g>
                    </svg>
                  </div>
                  <h3>{t("serviceSteps.body.request")}</h3>
                </li>
                <li data-aos="fade-left">
                  <div className="section-5-process-icon-doted">
                    <svg
                      preserveAspectRatio="none"
                      data-bbox="57 19.965 86 160.07"
                      viewBox="57 19.965 86 160.07"
                      height={200}
                      width={200}
                      xmlns="http://www.w3.org/2000/svg"
                      data-type="shape"
                      role="presentation"
                      aria-hidden="true"
                      style={{}}
                    >
                      <g>
                        <path d="M77.799 151.975a5.975 5.975 0 0 0 0 8.445 5.963 5.963 0 0 0 4.228 1.756 5.918 5.918 0 0 0 4.217-1.756 5.975 5.975 0 0 0 0-8.445 5.977 5.977 0 0 0-8.445 0z"></path>
                        <path d="M114.889 114.886a5.992 5.992 0 0 0 0 8.457 6 6 0 0 0 4.229 1.744 5.994 5.994 0 0 0 4.217-1.744 5.993 5.993 0 0 0 0-8.457 5.976 5.976 0 0 0-8.446 0z"></path>
                        <path d="M132.808 95.772a5.992 5.992 0 0 0 0 8.457 5.995 5.995 0 0 0 4.228 1.744 5.995 5.995 0 0 0 4.218-1.744 5.993 5.993 0 0 0 0-8.457 5.978 5.978 0 0 0-8.446 0z"></path>
                        <path d="M58.747 21.712a5.992 5.992 0 0 0 0 8.457 6.003 6.003 0 0 0 4.228 1.744 5.999 5.999 0 0 0 4.218-1.744 5.992 5.992 0 0 0 0-8.457 5.978 5.978 0 0 0-8.446 0z"></path>
                        <path d="M58.747 169.834a5.992 5.992 0 0 0 0 8.457 5.999 5.999 0 0 0 4.228 1.744 5.995 5.995 0 0 0 4.218-1.744 5.992 5.992 0 0 0 0-8.457 5.978 5.978 0 0 0-8.446 0z"></path>
                        <path d="M96.35 133.437a5.955 5.955 0 0 0 0 8.445 5.952 5.952 0 0 0 4.217 1.745 6 6 0 0 0 4.229-1.745c2.328-2.342 2.328-6.115 0-8.445a5.957 5.957 0 0 0-8.446 0z"></path>
                        <path d="M114.889 77.807a5.977 5.977 0 0 0 0 8.445 5.997 5.997 0 0 0 4.217 1.744 6.005 6.005 0 0 0 4.229-1.744c2.328-2.341 2.328-6.116 0-8.445a5.957 5.957 0 0 0-8.446 0z"></path>
                        <path d="M77.799 40.718c-2.33 2.33-2.33 6.104 0 8.446a6 6 0 0 0 4.228 1.743 5.996 5.996 0 0 0 4.217-1.743c2.33-2.342 2.33-6.116 0-8.446a5.954 5.954 0 0 0-8.445 0z"></path>
                        <path d="M96.339 59.256a5.975 5.975 0 0 0 0 8.445 5.955 5.955 0 0 0 4.228 1.756 5.979 5.979 0 0 0 4.229-10.201 5.992 5.992 0 0 0-8.457 0z"></path>
                      </g>
                    </svg>
                  </div>
                </li>
                <li data-aos="fade-left">
                  <div className="section-5-process-icon">
                    <svg
                      preserveAspectRatio="xMidYMid meet"
                      data-bbox="19.79 18.56 60.49 60.29"
                      viewBox="19.79 18.56 60.49 60.29"
                      xmlns="http://www.w3.org/2000/svg"
                      data-type="shape"
                      role="presentation"
                      aria-hidden="true"
                      aria-label
                    >
                      <g>
                        <path d="M44.77 31.28c-1.01 1.01-1.61 2.42-1.61 3.53 0 .71.5 1.31 1.31 1.31.71 0 1.31-.61 1.31-1.31 0-.3.3-1.11.91-1.71.5-.5.5-1.31 0-1.81-.71-.5-1.51-.5-1.92 0Z"></path>
                        <path d="M75.22 68.78c-2.72-.81-6.55-1.41-11.19-1.82-.71-.1-1.31.4-1.41 1.11-.1.71.4 1.31 1.11 1.41 9.68.91 13.11 2.42 13.91 3.12-.5.4-2.42 1.51-9.17 2.52-4.84.71-10.59 1.21-16.23 1.31 3.93-6.86 16.74-30.04 16.74-39.02-.1-10.49-8.47-18.85-18.95-18.85s-18.85 8.47-18.85 18.85 12.7 32.36 16.64 39.02c-6.35-.1-12.4-.5-17.14-1.31-6.25-1.01-7.86-2.12-8.27-2.52.6-.6 3.43-1.92 11.39-2.92.71-.1 1.21-.71 1.11-1.41-.1-.71-.71-1.21-1.41-1.11-3.93.5-7.16 1.11-9.38 1.81-1.82.61-4.33 1.61-4.33 3.53 0 1.81 2.32 2.82 3.02 3.12 6.35 2.62 20.26 3.23 27.22 3.23s20.47-.81 26.82-3.12c1.51-.5 3.43-1.41 3.43-3.13 0-2.12-2.92-3.23-5.04-3.83Zm-25.2-47.89c8.97 0 16.33 7.36 16.43 16.43 0 8.17-12.3 30.65-16.43 37.81-3.83-6.45-16.43-28.43-16.33-37.81 0-9.07 7.36-16.43 16.33-16.43Z"></path>
                        <path d="M61.41 36.02c0-6.25-5.04-11.39-11.39-11.39s-11.39 5.14-11.39 11.39 5.04 11.39 11.39 11.39 11.39-5.14 11.39-11.39Zm-20.16 0c0-4.84 3.93-8.87 8.87-8.87s8.87 3.93 8.87 8.87-3.93 8.87-8.87 8.87-8.87-3.93-8.87-8.87Z"></path>
                      </g>
                    </svg>
                  </div>
                  <h3>{t("serviceSteps.body.attend_the_service")}</h3>
                </li>
                <li data-aos="fade-left">
                  <div className="section-5-process-icon-doted">
                    <svg
                      preserveAspectRatio="none"
                      data-bbox="57 19.965 86 160.07"
                      viewBox="57 19.965 86 160.07"
                      height={200}
                      width={200}
                      xmlns="http://www.w3.org/2000/svg"
                      data-type="shape"
                      role="presentation"
                      aria-hidden="true"
                      style={{}}
                    >
                      <g>
                        <path d="M77.799 151.975a5.975 5.975 0 0 0 0 8.445 5.963 5.963 0 0 0 4.228 1.756 5.918 5.918 0 0 0 4.217-1.756 5.975 5.975 0 0 0 0-8.445 5.977 5.977 0 0 0-8.445 0z"></path>
                        <path d="M114.889 114.886a5.992 5.992 0 0 0 0 8.457 6 6 0 0 0 4.229 1.744 5.994 5.994 0 0 0 4.217-1.744 5.993 5.993 0 0 0 0-8.457 5.976 5.976 0 0 0-8.446 0z"></path>
                        <path d="M132.808 95.772a5.992 5.992 0 0 0 0 8.457 5.995 5.995 0 0 0 4.228 1.744 5.995 5.995 0 0 0 4.218-1.744 5.993 5.993 0 0 0 0-8.457 5.978 5.978 0 0 0-8.446 0z"></path>
                        <path d="M58.747 21.712a5.992 5.992 0 0 0 0 8.457 6.003 6.003 0 0 0 4.228 1.744 5.999 5.999 0 0 0 4.218-1.744 5.992 5.992 0 0 0 0-8.457 5.978 5.978 0 0 0-8.446 0z"></path>
                        <path d="M58.747 169.834a5.992 5.992 0 0 0 0 8.457 5.999 5.999 0 0 0 4.228 1.744 5.995 5.995 0 0 0 4.218-1.744 5.992 5.992 0 0 0 0-8.457 5.978 5.978 0 0 0-8.446 0z"></path>
                        <path d="M96.35 133.437a5.955 5.955 0 0 0 0 8.445 5.952 5.952 0 0 0 4.217 1.745 6 6 0 0 0 4.229-1.745c2.328-2.342 2.328-6.115 0-8.445a5.957 5.957 0 0 0-8.446 0z"></path>
                        <path d="M114.889 77.807a5.977 5.977 0 0 0 0 8.445 5.997 5.997 0 0 0 4.217 1.744 6.005 6.005 0 0 0 4.229-1.744c2.328-2.341 2.328-6.116 0-8.445a5.957 5.957 0 0 0-8.446 0z"></path>
                        <path d="M77.799 40.718c-2.33 2.33-2.33 6.104 0 8.446a6 6 0 0 0 4.228 1.743 5.996 5.996 0 0 0 4.217-1.743c2.33-2.342 2.33-6.116 0-8.446a5.954 5.954 0 0 0-8.445 0z"></path>
                        <path d="M96.339 59.256a5.975 5.975 0 0 0 0 8.445 5.955 5.955 0 0 0 4.228 1.756 5.979 5.979 0 0 0 4.229-10.201 5.992 5.992 0 0 0-8.457 0z"></path>
                      </g>
                    </svg>
                  </div>
                </li>
                <li data-aos="fade-left">
                  <div className="section-5-process-icon">
                    <svg
                      preserveAspectRatio="xMidYMid meet"
                      data-bbox="2.868 12.72 94.905 78.84"
                      viewBox="2.868 12.72 94.905 78.84"
                      xmlns="http://www.w3.org/2000/svg"
                      data-type="shape"
                      role="presentation"
                      aria-hidden="true"
                      aria-label
                    >
                      <g>
                        <path
                          d="M82.46 50.17H64.49c-.81 0-1.47-.66-1.47-1.47V36.82c0-5.76 4.69-10.47 10.47-10.47s10.45 4.69 10.45 10.47V48.7c0 .81-.64 1.47-1.47 1.47Zm-10.45-2.94V29.44c-3.44.69-6.05 3.73-6.05 7.38v10.41h6.05Zm2.94-17.79v17.79h6.03V36.82c0-3.64-2.6-6.69-6.03-7.38Zm13.24 52.89H10.87l-3.88 6.29h86.7l-3.88-6.29h-1.6ZM11 79.39h-.98c-.52 0-.97.26-1.25.7l-5.68 9.23c-.61 1 .11 2.24 1.25 2.24h91.98c1.15 0 1.83-1.27 1.23-2.24l-5.68-9.23c-.26-.44-.74-.7-1.25-.7h-.96v-55.6h2.65c.81 0 1.47-.66 1.45-1.47v-8.13c0-.81-.66-1.47-1.47-1.47H8.37c-.81 0-1.47.66-1.47 1.47v8.13c0 .81.66 1.47 1.47 1.47h2.65v55.59Zm75.71 0v-55.6H13.96v55.59h22.06V53.44c0-.81.66-1.47 1.47-1.47H64.9c.81 0 1.47.66 1.47 1.47v25.94h20.36Zm-47.75 0V54.92H49.7v24.47H38.96Zm13.69 0V54.92h10.78v24.47H52.65ZM9.84 20.85v-5.19h80.99v5.19H9.84Zm28.07 29.32H19.94c-.81 0-1.47-.66-1.47-1.47V36.82c0-5.76 4.69-10.47 10.45-10.47s10.45 4.69 10.45 10.47V48.7c.02.81-.64 1.47-1.45 1.47Zm-16.5-2.94h.02V36.82c0-3.65 2.59-6.68 6.02-7.37v17.79h-6.03Zm8.98 0V29.44c3.45.68 6.05 3.74 6.05 7.38v10.41h-6.05Z"
                          fillRule="evenodd"
                        />
                      </g>
                    </svg>
                  </div>
                  <h3>{t("serviceSteps.body.service_completed")}</h3>
                </li>
                <li data-aos="fade-left">
                  <div className="section-5-process-icon-doted">
                    <svg
                      preserveAspectRatio="none"
                      data-bbox="57 19.965 86 160.07"
                      viewBox="57 19.965 86 160.07"
                      height={200}
                      width={200}
                      xmlns="http://www.w3.org/2000/svg"
                      data-type="shape"
                      role="presentation"
                      aria-hidden="true"
                      style={{}}
                    >
                      <g>
                        <path d="M77.799 151.975a5.975 5.975 0 0 0 0 8.445 5.963 5.963 0 0 0 4.228 1.756 5.918 5.918 0 0 0 4.217-1.756 5.975 5.975 0 0 0 0-8.445 5.977 5.977 0 0 0-8.445 0z"></path>
                        <path d="M114.889 114.886a5.992 5.992 0 0 0 0 8.457 6 6 0 0 0 4.229 1.744 5.994 5.994 0 0 0 4.217-1.744 5.993 5.993 0 0 0 0-8.457 5.976 5.976 0 0 0-8.446 0z"></path>
                        <path d="M132.808 95.772a5.992 5.992 0 0 0 0 8.457 5.995 5.995 0 0 0 4.228 1.744 5.995 5.995 0 0 0 4.218-1.744 5.993 5.993 0 0 0 0-8.457 5.978 5.978 0 0 0-8.446 0z"></path>
                        <path d="M58.747 21.712a5.992 5.992 0 0 0 0 8.457 6.003 6.003 0 0 0 4.228 1.744 5.999 5.999 0 0 0 4.218-1.744 5.992 5.992 0 0 0 0-8.457 5.978 5.978 0 0 0-8.446 0z"></path>
                        <path d="M58.747 169.834a5.992 5.992 0 0 0 0 8.457 5.999 5.999 0 0 0 4.228 1.744 5.995 5.995 0 0 0 4.218-1.744 5.992 5.992 0 0 0 0-8.457 5.978 5.978 0 0 0-8.446 0z"></path>
                        <path d="M96.35 133.437a5.955 5.955 0 0 0 0 8.445 5.952 5.952 0 0 0 4.217 1.745 6 6 0 0 0 4.229-1.745c2.328-2.342 2.328-6.115 0-8.445a5.957 5.957 0 0 0-8.446 0z"></path>
                        <path d="M114.889 77.807a5.977 5.977 0 0 0 0 8.445 5.997 5.997 0 0 0 4.217 1.744 6.005 6.005 0 0 0 4.229-1.744c2.328-2.341 2.328-6.116 0-8.445a5.957 5.957 0 0 0-8.446 0z"></path>
                        <path d="M77.799 40.718c-2.33 2.33-2.33 6.104 0 8.446a6 6 0 0 0 4.228 1.743 5.996 5.996 0 0 0 4.217-1.743c2.33-2.342 2.33-6.116 0-8.446a5.954 5.954 0 0 0-8.445 0z"></path>
                        <path d="M96.339 59.256a5.975 5.975 0 0 0 0 8.445 5.955 5.955 0 0 0 4.228 1.756 5.979 5.979 0 0 0 4.229-10.201 5.992 5.992 0 0 0-8.457 0z"></path>
                      </g>
                    </svg>
                  </div>
                </li>
                <li data-aos="fade-left">
                  <div className="section-5-process-icon">
                    <svg
                      preserveAspectRatio="none"
                      data-bbox="13.04 14.707 74.59 74.863"
                      viewBox="13.04 14.707 74.59 74.863"
                      xmlns="http://www.w3.org/2000/svg"
                      data-type="shape"
                      role="presentation"
                      aria-hidden="true"
                      aria-label
                    >
                      <g>
                        <path
                          d="M70.36 82.38c-9.52 0-17.26-7.75-17.26-17.27s7.75-17.26 17.26-17.26 17.27 7.75 17.27 17.26c-.01 9.52-7.75 17.27-17.27 17.27Zm0-32.21c-8.24 0-14.94 6.7-14.94 14.94s6.7 14.94 14.94 14.94 14.94-6.7 14.94-14.94c-.01-8.24-6.72-14.94-14.94-14.94Z"
                          fillRule="evenodd"
                        />
                        <path
                          d="M67.1 71.94c-.29 0-.6-.12-.83-.33l-4.82-4.82c-.45-.45-.45-1.19 0-1.64.45-.45 1.19-.45 1.64 0l4 4 10.5-10.5c.45-.45 1.19-.45 1.64 0 .45.45.45 1.19 0 1.64L67.9 71.62c-.22.2-.51.32-.81.32Z"
                          fillRule="evenodd"
                        />
                        <path
                          d="M70.36 89.57H14.2c-.64 0-1.16-.52-1.16-1.16V35.24c0-.29.1-.57.29-.77L30.62 15.1c.22-.25.54-.39.87-.39h38.87c.64 0 1.16.52 1.16 1.16v33.14c0 .64-.52 1.16-1.16 1.16-8.24 0-14.94 6.7-14.94 14.94s6.7 14.94 14.94 14.94c.64 0 1.16.52 1.16 1.16v7.2c0 .64-.52 1.16-1.16 1.16Zm-55-2.32H69.2v-4.92c-8.98-.6-16.1-8.1-16.1-17.22s7.12-16.63 16.1-17.22V17.03H32L15.36 35.67v51.58Z"
                          fillRule="evenodd"
                        />
                        <path
                          d="M31.58 36.28H14.2a1.17 1.17 0 0 1-.86-1.95l17.37-19.24c.32-.36.83-.48 1.28-.3.45.17.74.61.74 1.09v19.25c.01.65-.51 1.16-1.15 1.16Zm-14.77-2.32h13.61V18.89L16.81 33.96Z"
                          fillRule="evenodd"
                        />
                        <path
                          d="M56.61 51.27H22.93a1.16 1.16 0 0 1 0-2.32H56.6a1.16 1.16 0 0 1 0 2.32Z"
                          fillRule="evenodd"
                        />
                        <path
                          d="M49.96 61.5H22.93a1.16 1.16 0 0 1 0-2.32h27.03a1.16 1.16 0 0 1 0 2.32Z"
                          fillRule="evenodd"
                        />
                        <path
                          d="M50.21 71.74H22.93a1.16 1.16 0 0 1 0-2.32h27.28a1.16 1.16 0 0 1 0 2.32Z"
                          fillRule="evenodd"
                        />
                        <path
                          d="M58.28 81.97H22.93a1.16 1.16 0 0 1 0-2.32h35.34a1.16 1.16 0 0 1 0 2.32Z"
                          fillRule="evenodd"
                        />
                      </g>
                    </svg>
                  </div>
                  <h3>{t("serviceSteps.body.invoice_feedback")}</h3>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="process-car">
              <ul className="process-car-inner">
                <li data-aos="fade-left">
                  <img src="./assets/img/customer-icon.png" alt="customer" />
                  <p className="process-p">{t("serviceSteps.body.response")}</p>
                </li>
                <li data-aos="fade-left">
                  <img src="./assets/img/car.png" alt="car" />
                  <p className="process-p">
                  {t("serviceSteps.body.instant")} <br /> {t("serviceSteps.body.scheduled")}
                  </p>
                </li>
                <li data-aos="fade-left">
                  <img src="./assets/img/setting.png" alt="setting" />
                  <p className="process-p">{t("serviceSteps.body.inspection_rapair_pay")}Inspection-Rapair-Pay</p>
                </li>
              </ul>
              <div className="up-line-div" data-aos="zoom-in">
                <div className="up-line-div-img">
                  <img src="./assets/img/up-line.png" alt="up-line" />
                </div>
                <div className="up-line-div-img">
                  <img src="./assets/img/up-line.png" alt="up-line" />
                </div>
                <div className="up-line-div-img">
                  <img src="./assets/img/up-line.png" alt="up-line" />
                </div>
                <div className="up-line-div-img">
                  <img src="./assets/img/up-line.png" alt="up-line" />
                </div>
              </div>
              <div className="process-line" data-aos="zoom-in"></div>
              <div className="down-line-div" data-aos="zoom-in">
                <div className="down-line-div-img">
                  <img src="./assets/img/down-line.png" alt="down-line" />
                </div>
                <div className="down-line-div-img">
                  <img src="./assets/img/down-line.png" alt="down-line" />
                </div>
                <div className="down-line-div-img">
                  <img src="./assets/img/down-line.png" alt="down-line" />
                </div>
              </div>
              <ul className="process_content">
                <li data-aos="fade-left">
                  <p>{t("serviceSteps.body.response")}</p>
                </li>
                <li data-aos="fade-left">
                  <p>{t("serviceSteps.body.instant")}<br />{t("serviceSteps.body.scheduled")}
                  </p>
                </li>
                <li data-aos="fade-left">
                  <p>{t("serviceSteps.body.inspection_rapair_pay")}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section id="section-6" className="section-6">
        <div className="video-wrapper">
          <video autoPlay muted loop playsInline preload="metadata">
            <source src="./assets/file.mp4" type="video/mp4" />
          </video>
        </div>
      </section>
    </>
  );
}

export default SectionFive;
