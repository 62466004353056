import React from "react";
import { useTranslation } from "react-i18next";

function CopyRight() {
  const [t, i18n] = useTranslation("global");
  return (
    <div className="copy-right">
      <div className="container">
        <div className="copy-right-inner">
          <p>{t("copyright")}</p>
        </div>
      </div>
    </div>
  );
}

export default CopyRight;
