import { React, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import { useTranslation } from "react-i18next";

function SectionEight() {
  const [t, i18n] = useTranslation("global");
  const carouselOption = {
    item: 1,
    loop: true,
    autoplayTimeout: 4000,
    animateOut: "fadeOut",
    margin: 10,
    nav: true,
    dots: true,
    animateIn: "fadeIn",

    navText: ['<div class="prev-btn">', '<div class="next-btn">'],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  return (
    <>
      <section id="section-8" className="section-8">
        <div className="container-fluid">
          <div className="row">
            <div className="section-8-outer">
              <div className="section-8-outer-left">
                <h2>
                  {t("what_does_musalih_ffer.header.l1")}
                  <br />
                  {t("what_does_musalih_ffer.header.l2")}
                  <br />
                  {t("what_does_musalih_ffer.header.l3")}
                </h2>
              </div>
              <div className="section-8-outer-right">
                <div className="section-8-outer-right-inner">
                  <div className="section-8-outer-right-inner-content">
                    <img src="assets/img/30-Days-warrenty.png" alt="" />
                    <h2>
                      {t("what_does_musalih_ffer.body.days_warranty.header.l1")}
                      <br />
                      {t("what_does_musalih_ffer.body.days_warranty.header.l2")}
                    </h2>
                    <p>
                      {t("what_does_musalih_ffer.body.days_warranty.body.l1")}
                      <br />
                      {t("what_does_musalih_ffer.body.days_warranty.body.l2")}
                      <br />
                      {t("what_does_musalih_ffer.body.days_warranty.body.l3")}
                    </p>
                  </div>
                  <div className="section-8-outer-right-inner-content">
                    <img src="assets/img/schedule_2669764.png" alt="" />
                    <h2>
                      {t(
                        "what_does_musalih_ffer.body.scheduling_options.header.l1"
                      )}
                      <br />
                      {t(
                        "what_does_musalih_ffer.body.scheduling_options.header.l2"
                      )}
                    </h2>
                    <p>
                      {t(
                        "what_does_musalih_ffer.body.scheduling_options.body.l1"
                      )}
                      <br />
                      {t(
                        "what_does_musalih_ffer.body.scheduling_options.body.l2"
                      )}
                    </p>
                  </div>
                  <div className="section-8-outer-right-inner-content">
                    <img src="assets/img/award_3395949.png" alt="" />
                    <h2>
                      {t("what_does_musalih_ffer.body.quality_work.header")}
                    </h2>
                    <p>
                      {t("what_does_musalih_ffer.body.quality_work.body.l1")}
                      <br />
                      {t("what_does_musalih_ffer.body.quality_work.body.l2")}
                    </p>
                  </div>
                  <div className="section-8-outer-right-inner-content">
                    <img src="assets/img/placeholder_6126362.png" alt="" />
                    <h2>
                      {t("what_does_musalih_ffer.body.local_services.header")}
                    </h2>
                    <p>
                      {t("what_does_musalih_ffer.body.local_services.body.l1")}
                      <br />
                      {t("what_does_musalih_ffer.body.local_services.body.l2")}
                    </p>
                  </div>
                  <div className="section-8-outer-right-inner-content">
                    <img src="assets/img/workers_4490362.png" alt="" />
                    <h2>
                      {t("what_does_musalih_ffer.body.skilled_maher.header.l1")}
                      <br />
                      {t("what_does_musalih_ffer.body.skilled_maher.header.l2")}
                    </h2>
                    <p>
                      {t("what_does_musalih_ffer.body.skilled_maher.body.l1")}
                      <br />
                      {t("what_does_musalih_ffer.body.skilled_maher.body.l2")}
                    </p>
                  </div>
                  <div className="section-8-outer-right-inner-content">
                    <img src="assets/img/customer-service_3883772.png" alt="" />
                    <h2>
                      {t(
                        "what_does_musalih_ffer.body.prompt_response.header.l1"
                      )}
                      <br />
                      {t(
                        "what_does_musalih_ffer.body.prompt_response.header.l2"
                      )}
                    </h2>
                    <p>
                      {t("what_does_musalih_ffer.body.prompt_response.body.l1")}
                      <br />
                      {t("what_does_musalih_ffer.body.prompt_response.body.l2")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="section-9" className="section-9">
        <div className="container">
          <div className="row owl-cd">
            <OwlCarousel
              id="section_9"
              className="owl-theme"
              {...carouselOption}
            >
              <div className="item">
                <div className="col-xl-10 mx-auto">
                  <div className="row section-9-outer-div">
                    <div className="section-9-left-content-outer">
                      <div className="section-9-left-content">
                        <p>
                          Sulaiman Al-Harthy, the CEO of Oman Arab Bank, visited
                          the headquarters of the Musalih platform to learn
                          about the company, its operations, and its initiatives
                          in supporting Omani youth in the field of freelancing.
                          Al-Harthy was accompanied by Khaled Al-Amri, Acting
                          Head of Retail Banking, and Jumaa Al-Fulaiti,
                          Executive Director of CEO's Office.
                        </p>
                        <div className="section-9-left-content-1">
                          <p>@oman_arab_bank</p>
                        </div>
                        <div className="section-9-left-content-2">
                          <p>#محظوظ_من_كان_عمله_هوايته</p>
                          <p>#مع_مُصلِّح_كل_شي_يتصلح</p>
                        </div>
                        <ul className="header_social">
                          <li>
                            <a
                              target="_blank"
                              href="https://www.facebook.com/Musalihapp/"
                            >
                              <img src="/assets/img/fb-black.png" alt="" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://twitter.com/musalih_om?lang=en"
                            >
                              <img src="/assets/img/twitter-black.png" alt="" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.linkedin.com/company/musalih/"
                            >
                              <img src="/assets/img/linkdin-blank.png" alt="" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.instagram.com/musalih_om/?hl=en"
                            >
                              <img src="/assets/img/insta-black.png" alt="" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="section-9-right-content-outer">
                      <div className="section-9-right-img">
                        <img src="/assets/img/section-9-3.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="col-xl-10 mx-auto section-9-outer-div-main">
                  <div className="row section-9-outer-div">
                    <div className="section-9-left-content-outer">
                      <div className="section-9-left-content">
                        <p>
                          A glimpse of Dr. Badr Al-Kharousi, Head of Development
                          Track at the National Employment Program, during his
                          visit with the Musalih team to the Technical and
                          Administrative Training Institute. The visit aimed to
                          explore the training program organized in
                          collaboration between the Ministry of Labor, Musalih
                          platform, and the Technical and Administrative
                          Training Institute.
                        </p>
                        <div className="section-9-left-content-1">
                          <p>@labour_oman</p>
                          <p>@tati.tdi</p>
                        </div>
                        <div className="section-9-left-content-2">
                          <p>#محظوظ_من_كان_عمله_هوايته</p>
                          <p>#مع_مُصلِّح_كل_شي_يتصلح</p>
                        </div>
                        <ul className="header_social">
                          <li>
                            <a
                              target="_blank"
                              href="https://www.facebook.com/Musalihapp/"
                            >
                              <img src="/assets/img/fb-black.png" alt="" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://twitter.com/musalih_om?lang=en"
                            >
                              <img src="/assets/img/twitter-black.png" alt="" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.linkedin.com/company/musalih/"
                            >
                              <img src="/assets/img/linkdin-blank.png" alt="" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.instagram.com/musalih_om/?hl=en"
                            >
                              <img src="/assets/img/insta-black.png" alt="" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="section-9-right-content-outer">
                      <div className="section-9-right-img">
                        <img src="/assets/img/section-9-1.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="col-xl-10 mx-auto">
                  <div className="row section-9-outer-div">
                    <div className="section-9-left-content-outer">
                      <div className="section-9-left-content">
                        <p>
                          In collaboration with the National Employment Program
                          and the College of Technology in Al Seeb, Musalih
                          Platform presented an introductory program about the
                          services offered on the platform. The program
                          highlighted the importance of these services in skill
                          development and also presented available job
                          opportunities in the maintenance and repair services
                          sector. ​
                        </p>
                        <div className="section-9-left-content-1">
                          <p>@labour_oman</p>
                          <p>@tati.tdi</p>
                        </div>
                        <div className="section-9-left-content-2">
                          <p>#محظوظ_من_كان_عمله_هوايته</p>
                          <p>#مع_مُصلِّح_كل_شي_يتصلح</p>
                        </div>
                        <ul className="header_social">
                          <li>
                            <a
                              target="_blank"
                              href="https://www.facebook.com/Musalihapp/"
                            >
                              <img src="/assets/img/fb-black.png" alt="" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://twitter.com/musalih_om?lang=en"
                            >
                              <img src="/assets/img/twitter-black.png" alt="" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.linkedin.com/company/musalih/"
                            >
                              <img src="/assets/img/linkdin-blank.png" alt="" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.instagram.com/musalih_om/?hl=en"
                            >
                              <img src="/assets/img/insta-black.png" alt="" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="section-9-right-content-outer">
                      <div className="section-9-right-img">
                        <img src="/assets/img/section-9-2.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>
      <section id="section-10" className="section-10">
        <div className="section-10-inner">
          <div className="container">
            <div className="section-10-inner-banner owl-cd">
              <OwlCarousel
                id="slider_10"
                className="owl-theme"
                {...carouselOption}
              >
                <div className="item">
                  <img
                    className="upper-quote"
                    src="/assets/img/upper-quote.png"
                    alt=""
                  />
                  <img src="/assets/img/banner_10_1.jpg" alt="" />
                  <img
                    className="lower-quote"
                    src="/assets/img/lower-quote.png"
                    alt=""
                  />
                </div>
                <div className="item">
                  <img
                    className="upper-quote"
                    src="/assets/img/upper-quote.png"
                    alt=""
                  />
                  <img src="/assets/img/banner_10_2.jpg" alt="" />
                  <img
                    className="lower-quote"
                    src="/assets/img/lower-quote.png"
                    alt=""
                  />
                </div>
                <div className="item">
                  <img
                    className="upper-quote"
                    src="/assets/img/upper-quote.png"
                    alt=""
                  />
                  <img src="/assets/img/banner_10_3.png" alt="" />
                  <img
                    className="lower-quote"
                    src="/assets/img/lower-quote.png"
                    alt=""
                  />
                </div>
                <div className="item">
                  <img
                    className="upper-quote"
                    src="/assets/img/upper-quote.png"
                    alt=""
                  />
                  <img src="/assets/img/banner_10_4.png" alt="" />
                  <img
                    className="lower-quote"
                    src="/assets/img/lower-quote.png"
                    alt=""
                  />
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionEight;
