import React from 'react'
import { useTranslation } from "react-i18next";

function About() {
  const [t, i18n] = useTranslation("global");
  return (
    <section id="about" className="about p-0" style={{backgroundImage: 'url("./assets/img/about-image.jpg")'}}>
  <div className="container-flui">
    <div className="row">
      <div className="offset-xl-2 col-xl-4 col-lg-6 about-left-section" data-aos="fade-right">
        <div className="about-left-section-content">
          <h2>{t("about.header")}</h2>
          <p>
            {t("about.p1")}
          </p>
          <p>
          {t("about.p2")}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

  );
}

export default About