import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useTranslation } from "react-i18next";

function MyAppSection() {
  const [t, i18n] = useTranslation("global");
  const carouselOption = {
    item: 1,
    loop: true,
    autoplayTimeout: 4000,
    animateOut: "fadeOut",
    margin: 10,
    nav: true,
    dots: true,
    animateIn: "fadeIn",

    navText: ['<div class="prev-btn">', '<div class="next-btn">'],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    <section id="section-my-app" className="section-my-app">
      <div className="container">
        <div className="row">
          <div className="section-my-app-inner">
            <div className="section-my-app-inner-left">
              <h2>
                {t("my_app.header.t1")}
                <br />
                {t("my_app.header.t2")}
              </h2>
              <p>
              {t("my_app.body.offer")}
              </p>
              <ul>
                <li>
                  <a
                    href="https://apps.apple.com/om/app/musalih/id1523794335"
                    targte="_blank"
                  >
                    <img src="./assets/img/app_store.png" alt="app_store" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.musalih.app&hl=en_AU"
                    targte="_blank"
                  >
                    <img src="./assets/img/google_pay.png" alt="google_pay" />
                  </a>
                </li>
              </ul>
              <div className="section-my-app-inner-left-button">
                <a href="#" className="section-my-app-btn">
                  {t("my_app.body.download_now")}
                </a>
              </div>
            </div>
            <div
              className="section-my-app-inner-right"
              style={{ display: "none" }}>

              <OwlCarousel
                id="section-my-app_slider"
                className="owl-theme" {...carouselOption}>
                <div className="item" data-aos="fade-left">
                  <img src="./assets/img/app-1.png" alt="app" />
                </div>
                <div className="item" data-aos="fade-left">
                  <img src="./assets/img/app-2.png" alt="app" />
                </div>
              </OwlCarousel>

            </div>
            <div className="section-my-app-inner-right">
              <img src="./assets/img/two-image.png" alt="two-image" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MyAppSection;
