import React from "react";
import { useTranslation } from "react-i18next";

function WelcomeSection() {
  const [t, i18n] = useTranslation("global");
  return (
    <section id="welcome-section">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-xl-6 welcome-section-left">
            <h2>{t("welcomeSection.section-left.l1")}<br/>{t("welcomeSection.section-left.l2")}</h2>
          </div>
          <div className="col-md-6 col-xl-6 welcome-section-right">
            <p>
              {t("welcomeSection.section-right.l1")}<br/>{t("welcomeSection.section-right.l2")}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WelcomeSection;
