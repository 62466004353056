import React from "react";
import { useTranslation } from "react-i18next";

function Footer() {
  const [t, i18n] = useTranslation("global");
  return (
    <footer id="footer" className="footer">
      <div className="container">
        <div className="row">
          <div className="service-inner-title">
            <h2>{t("footer.contact")}</h2>
          </div>
          <div className="footer-map">
            <div className="footer-map-inner">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d234022.3655855602!2d58.424516249999996!3d23.58282475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2698011414c3347%3A0x429123379d85b480!2sMusalih!5e0!3m2!1sen!2suk!4v1696863106775!5m2!1sen!2suk"
                width="100%"
                height={450}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="footer_contact_div_outer">
          <div className="footer_contact_div_outer_left">
            <div className="footer-inquiry">
              <div className="footer-title">
                <h2>{t("footer.inquiries")}</h2>
                <p>
                  {t("footer.for_any_inquiries")}
                  <br />
                  {t("footer.please_call")}
                </p>
              </div>
            </div>
            <div className="footer-Employment">
              <div className="footer-title">
                <h2>{t("footer.employment")}</h2>
                <p>
                  {t("footer.contact_us_at")}
                  <br />
                  {t("footer.horizon_integrated_business")}
                  <br />
                  <a href="mailto:Info@musalih.om">Info@musalih.om</a>
                </p>
              </div>
            </div>
            <div className="yellow-bar" />
          </div>
          <div className="footer_contact_div_outer_right">
            <div className="footer-Office">
              <div className="footer-title">
                <h2>{t("footer.head_office")}</h2>
                <p>
                  {t("footer.address.l1")}
                  <br />
                  {t("footer.address.l2")}
                </p>
                <div className="footer-Office-right">
                  <p>
                    <a href="mailto:Info@musalih.om">Info@musalih.om</a>
                  </p>
                  <p>{t("footer.whatsapp")}</p>
                  <p>{t("footer.social")}: @musalih_om</p>
                  <ul className="header_social">
                    <li>
                      <a
                        href="https://x.com/musalih_om?s=21&t=ANBW9YwhzKSZ-nKfzEUVsw"
                        target="_blank"
                      >
                        <img src="./assets/img/twitter.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/musalih_om?igsh=empod2ZhbGVnZTIx"
                        target="_blank"
                      >
                        <img src="./assets/img/instagram.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/Musaliih?mibextid=hu50Ix"
                        target="_blank"
                      >
                        <img src="./assets/img/facebook.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/musalih/"
                        target="_blank"
                      >
                        <img src="./assets/img/linkdin.png" alt="" />
                      </a>
                    </li>
                  </ul>
                  <p>
                  <br />
                    <a
                      href="https://musalih.om/Terms%20&%20Conditions.html"
                      target="_blank">
                      {t("footer.terms_and_conditions")}
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://musalih.om/Privacy%20Policy.html"
                      target="_blank">
                      {t("footer.privacy_policy")}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
