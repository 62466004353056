import { React, useEffect, useRef, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

function Header() {
  const refHeader = useRef(null);
  const [isBodyUpdate, setIsBodyUpdate] = useState(false);
  const [count, setCount] = useState(0);
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
  const [defaultLang, setDefaultLang] = useState("EN");
  const [t, i18n] = useTranslation("global");
  let co = 0;

  useEffect(() => {
    const selectBody = document.querySelector("body");
    selectBody.classList.toggle("mobile-nav-active");
  }, [isMobileMenuActive]);

  useEffect(() => {
    const selectHeader = document.querySelector("#header");
    const selectBody = document.querySelector("body");

    document.addEventListener("scroll", function (event) {
      toggleScrolled(selectHeader, selectBody);
    });
    window.addEventListener("load", function (event) {
      toggleScrolled(selectHeader, selectBody);
    });
  }, []);

  let lastScrollTop = 0;
  const toggleScrolled = (selectHeader, selectBody) => {
    if (
      !refHeader.current.classList.contains("scroll-up-sticky") &&
      !refHeader.current.classList.contains("sticky-top") &&
      !refHeader.current.classList.contains("fixed-top")
    )
      return;

    window.scrollY > 100
      ? selectBody.classList.add("scrolled")
      : selectBody.classList.remove("scrolled");

    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop > lastScrollTop && scrollTop > selectHeader.offsetHeight) {
      selectHeader.style.setProperty("position", "sticky", "important");
      selectHeader.style.top = `-${selectHeader.offsetHeight + 50}px`;
    } else if (scrollTop > selectHeader.offsetHeight) {
      selectHeader.style.setProperty("position", "sticky", "important");
      selectHeader.style.top = "0";
    } else {
      selectHeader.style.removeProperty("top");
      selectHeader.style.removeProperty("position");
    }
    lastScrollTop = scrollTop;
  };

  const mobileMenuClicked = () => {
    setIsMobileMenuActive((isMobileMenuActive) => !isMobileMenuActive);
  };

  const handleChangeLanguage = (lang) => {
    setDefaultLang(lang.toUpperCase());
    i18n.changeLanguage(lang);
  };

  return (
    <header
      ref={refHeader}
      id="header"
      className="header fixed-top d-flex align-items-center"
    >
      <div className="header-inner-div container-fluid d-flex align-items-center">
        <a href="index.html" className="logo d-flex align-items-center me-xl-0">
          <img src="/assets/img/header-logo.png" alt="logo" />
        </a>

        <ul className="header_social">
          <li>
            <a
              href="https://x.com/musalih_om?s=21&t=ANBW9YwhzKSZ-nKfzEUVsw"
              target="_blank"
            >
              <img src="/assets/img/twitter.png" alt="twitter" />
              {isMobileMenuActive}
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/musalih_om?igsh=empod2ZhbGVnZTIx"
              target="_blank"
            >
              <img src="/assets/img/instagram.png" alt="instagram" />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/Musaliih?mibextid=hu50Ix"
              target="_blank"
            >
              <img src="/assets/img/facebook.png" alt="facebook" />
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/musalih/" target="_blank">
              <img src="/assets/img/linkdin.png" alt="linkdin" />
            </a>
          </li>
        </ul>

        <ul className="header_earth">
          <li>
            <div className="dropdown">
              <button className="dropbtn">{defaultLang}</button>
              <div className="dropdown-content">
                <a
                  href="javascript: void(0)"
                  onClick={() => handleChangeLanguage("en")}
                >
                  ENG
                </a>
                <a
                  href="javascript: void(0)"
                  onClick={() => handleChangeLanguage("ar")}
                >
                  عربي
                </a>
              </div>
            </div>
          </li>
          <li>
            <img src="/assets/img/earth-globe (1).png" alt="earth-globe" />
          </li>
        </ul>
        {/* Nav Menu */}
        <nav id="navmenu" className="navmenu">
          <ul>
            <li className="active">
              <Link to="#home">{t("menu.home")}</Link>
            </li>
            <li>
              <Link to="#about">{t("menu.about")}</Link>
            </li>
            <li>
              <Link to="#service">{t("menu.services")}</Link>
            </li>
            <li>
              <Link to="#section-my-app">{t("menu.Musalih_app")}</Link>
            </li>
            <li>
              <Link to="#section-9">{t("menu.newsletter")}</Link>
            </li>
            <li>
              <Link to="#footer">{t("menu.contact")}</Link>
            </li>
          </ul>
          <i
            onClick={mobileMenuClicked}
            className={
              isMobileMenuActive
                ? "mobile-nav-toggle d-xl-none bi bi-x"
                : "mobile-nav-toggle d-xl-none bi bi-list"
            }
          />
        </nav>
        {/* End Nav Menu */}
      </div>
    </header>
  );
}

export default Header;
